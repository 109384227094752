.show_nav {
  position: fixed;
  top: -150px;
  width: 100%;
  transition: top 0.3s;
  padding-top: 18px;
  padding-bottom: 18px;
  z-index: 99999;
  background-color: #20477b;
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1),
    0px 10px 10px -5px rgba(0, 0, 0, 0.04);
}
.top-bar {
  background-color: #20477b;
}
.appointment-btn {
  background-color: #16a7e5;
  padding: 16px 36px;
  display: inline-block;
  float: right;
}
.emailid {
  color: #16a7e5;
  font-weight: 500;
}

.navbar-expand-xl .navbar-nav .nav-link {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.navbar {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
a.blue-btn:hover {
  color: #23508d !important;
  background-color: white;
  transition: all 0.5s ease-in-out;
}
a.white-btn:hover {
  background: #16a7e5 !important;
  color: white !important;
  transition: all 0.5s ease-in-out;
}
a.nav-link:hover {
  color: #16a7e5 !important;
  transition: all 0.5s ease-in-out;
}
.nav-link.active {
  color: #16a7e5 !important;
}
.navbar-brand a {
  padding-right: 67px;
}
.header {
  position: absolute;
  width: 100%;
  margin: 0 auto;
  padding-top: 18px;
  padding-bottom: 18px;
  border-bottom: 1px solid #3066ae;
  z-index: 2;
}
.massage-info {
  padding-left: 21px;
}
.nav-link {
  margin-right: 32px;
}
.navbar-brand {
  margin-right: 99px;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.dropdown-toggle {
  position: relative;
}
.troo {
  color: #16a7e5;
}
ul.dropdown-menu.show {
  padding: 0 !important;
  margin-top: 10px !important;
}
.dropdown-menu li:hover {
  background: #23508d;
}
.dropdown-menu li:hover a.dropdown-item {
  color: white;
}
.dropdown-toggle::after {
  vertical-align: inherit !important;
}
.social-icon a {
  margin-right: 8px;
  width: 28px;
  height: 28px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.social-icon a svg {
  color: white;
}
.social-icon a:hover {
  background: #16a7e5;
  border-radius: 3px;
  text-align: center;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #1e2125;
  background-color: transparent !important;
}

.dropdown-toggle::after {
  content: "";
  background: url("../../img/down-angle.png") center no-repeat;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
}
.navbar-nav .nav-item .nav-link {
  color: #fff !important;
}
.navbar-nav .active.nav-item .nav-link {
  color: #16a7e5 !important;
}
.dropdown-menu li .dropdownActive {
  background: #23508d;
}
ul.dropdown-menu a.dropdown-item {
  color: #022539;
  font-weight: 500;
  line-height: 1.21;
  font-family: "Inter", sans-serif;
  padding-left: 15px;
  padding-right: 10px;
  padding-bottom: 16px;
  padding-top: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
ul.dropdown-menu a.dropdown-item.dropdownActive {
  color: #fb991c;
  background-color: #022539 !important;
  border: 1px solid #ccc;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: initial !important;
}
.nav-item a svg {
  margin-left: 4px;
}
.navbar-expand-lg .navbar-collapse{
  justify-content: space-between;
}
  
@media only screen and (max-width: 1200px) {
  .navbar-toggler {
    background-color: white !important;
  }
  .navbar-expand-lg .navbar-collapse{
    justify-content: end;
  }
  .header {
    border-bottom: none;
  }
  #header {
    background: #23508D;
    /* position: relative; */
}
  .appointment-btn {
    padding: 16px 33px;
  }
  .talk-box.d-flex.align-items-center{
    display: none !important;
  }
}
@media (max-width: 991px) {
  .navbar-nav .nav-item .nav-link{
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 767px) {
  header.sticky {
    position: fixed;
  }
  .appointment-btn {
    padding: 9px 18px;
  }
  section#top-bar .col-xl-3.col-lg-4.col-md-6.col-sm-12 {
    margin-top: 0;
  }
  .navbar-brand a img {
    width: 100%;
    max-width: 196px;
  }
}

@media only screen and (max-width: 480px) {
  .navbar-brand a {
    padding-right: 0px !important;
  }
}

.privacy-btn a:first-child {
  border-left: 0;
}

.privacy-btn a {
  border-left: 2px solid #fff;
  padding: 0 10px 0 11px;
}
.subscribe h4.text-white span.fw-bold {
  font-size: 20px;
}

.subscribe h4.text-white {
  font-size: 16px !important;
}
.footer {
  background-color: #23508d;
  position: relative;
  padding-top: 127px;
  padding-bottom: 24px;
}
.footer::before {
  content: "";
  position: absolute;
  background: url("../../img/Footer BG.png") center no-repeat;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  pointer-events: none;
}
.ftr-logo {
  margin-bottom: 30px;
}
.ftr-para {
  margin-bottom: 27px;
}
.small-border {
  background: #16a7e5;
  border-radius: 100px;
  width: 30px;
  height: 4px;
  margin-bottom: 10px;
}
.ftr-items ul li {
  margin-bottom: 14px;
}
a:hover {
  /* color: #16a7e5 !important; */
  transition: all 0.5s ease-in-out;
}
.ftr-email {
  background: #20477b !important;
  border-radius: 5px;
}
.ftr-submit {
  background: #16a7e5 !important;
  border-radius: 5px;
  width: 100%;
  height: 50px;
}
.more-btn {
  position: relative;
}
.subcribe-wrap {
  padding-left: 70px;
  padding-right: 70px;
  text-align: center;
}
.first-box {
  max-width: 370px;
}
input#exampleInputEmail1 {
  background: #20477b !important;
  border-radius: 5px;
}
.subscribe h4 {
  margin-bottom: 23px;
}
.navigation-te h4 {
  margin-bottom: 18px;
}
.ftr-btm-border {
  border: 1px solid #d9d9d9;
  opacity: 0.2;
  margin-top: 83px;
  margin-bottom: 22px;
}
.more-btn::before {
  border-radius: 10px 0px 0px 10px;
  content: "";
  position: absolute;
  width: 5px;
  height: 51px;
  background-color: #fff;
  left: 0;
  bottom: 0px;
}
@media only screen and (max-width: 1200px) {
  .subcribe-wrap {
    padding-left: 0;
    padding-right: 0;
    text-align: left;
  }
}
@media only screen and (max-width: 991px) {
  .subcribe-wrap form {
    display: inline-block;
  }
  .footer {
    padding-top: 50px;
  }
  .social-media {
    padding-top: 10px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .more-btn {
    width: 100%;
    margin-bottom: 20px;
  }
  .footer {
    padding-top: 50px;
  }
}

.banner {
  background: url("../../img/shape-banner.png") center no-repeat;
  background-size: 100% 100%;
  padding-top: 173px;
  padding-bottom: 383px;
}
.b-left-content p {
  margin-bottom: 21px !important;
}
a.blue-btn {
  background: #16a7e5;
  box-shadow: 0px 7px 29px rgb(35 80 141 / 20%);
  border-radius: 5px;
  padding: 16px 18px;
  display: inline-block;
  position: relative;
  z-index: 1;
}
a.blue-btn::before {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: 5%;
  height: 100%;
  background-color: #fff;
  border-radius: 5px 0px 0px 5px;
}
a.white-btn {
  background: white;
  color: #23508d !important;
  border-radius: 5px;
  padding: 16px 18px;
  margin-left: 30px;
  box-shadow: 0px 7px 29px rgba(35, 80, 141, 0.2);
  display: inline-block;
}
.b-btn {
  padding-top: 21px;
  display: flex;
  column-gap: 10px;
}
.b-right-content h3::before {
  content: "";
  position: absolute;
  width: 25px;
  height: 3px;
  background: #16a7e5;
  border-radius: 100px;
  top: 10px;
  left: 0;
}
.b-right-content h3 {
  padding-left: 38px;
  position: relative;
}
.vedio-img {
  background: url("../../img/Video.png") center no-repeat;
  background-size: cover;
  width: 100%;
  padding-top: 133px;
  padding-bottom: 133px;
  margin-top: -240px;
}
.vedio-content {
  max-width: 302px;
  margin: 0 auto;
}
.play-btn {
  width: 116px;
  height: 116px;
  margin: 0 auto;
}
.vedio-content h2 {
  margin-bottom: 21px;
}
.b-left-content p {
  max-width: 585px;
}
section#banner:before {
  background-image: url(../../img/bg.png);
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  top: 40px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.b-left-content {
  position: relative;
  /* z-index: 99; */
}
section.vedio {
  position: relative;
  z-index: 99;
}

a.purple-btn::before {
  background: #23508d !important;
}
.about-banner {
  background: #f8f8fc url("../../img/about-bg.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.about-banner .row {
  position: relative;
  padding-top: 149px;
  padding-bottom: 84px;
}
.about-talk-box {
  float: right;
  background: #ffffff;
  box-shadow: 0px 8px 24px rgb(149 157 165 / 20%);
  border-radius: 5px;
  width: 100%;
  max-width: 244px;
  padding: 14px 14px 14px 25px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.breadcrumb-item + .breadcrumb-item::before{
  color: #fff !important;
}

.banner-text-wrap{
  padding-top: 50px;
}

@media only screen and (max-width: 991px) {
  .banner-text-wrap{
    padding-top: 100px;
  }
  .b-right-content{
    padding-top: 100px;
  }
  .b-left-content p{
    max-width: 100%;
  }
  .banner {
    background-size: cover;
    padding-top: 70px;
    padding-bottom: 200px;
  }
  .vedio-img {
    padding-top: 70px;
    padding-bottom: 70px;
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .about-talk-box {
    padding: 4px 15px;
    bottom: -16px;
  }
  a.white-btn {
    margin-left: 0;
  }
  .d-flex.align-items-center {
    flex-wrap: wrap;
  }
  .vedio-content h2.text-white.text-center {
    font-size: 20px !important;
  }
  section#banner a.blue-btn.text-white {
    /* margin-bottom: 13px; */
    width: 100%;
    text-align: center;
  }
  section#banner a.white-btn.text-white {
    width: 100%;
    text-align: center;
  }
  section#banner:after {
    display: none;
  }
}
.about-banner#banner:after {
  background-image: url(http://localhost:3000/static/media/bg.4ef4bfd….png);
  position: relative;
  width: 100%;
  height: 100%;
  content: "";
  top: 40px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media only screen and (max-width: 580px) {
  .vedio-img {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 480px) {
  a.white-btn {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 385px){
  .b-btn{
    flex-wrap: wrap;
    row-gap: 20px;
    display: inline-grid;
  }
}
@import url("https://fonts.googleapis.com/css2?family=Chivo&family=Poppins:wght@400;500&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body {
  font-family: "Chivo";
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
}
.container-1240 {
  max-width: 1240px !important;
  padding-right: 20px !important;
  padding-left: 20px !important;
  margin: 0 auto !important;
}

.women-medical img {
  width: 100%;
}
.map img {
  width: 100%;
}

/* typography */
h1 {
  font-family: "Chivo";
  font-size: 50px !important;
  line-height: 60px !important;
  letter-spacing: 1;
}
h2 {
  font-family: "Chivo";
  font-weight: 500;
  font-size: 36px !important;
  line-height: 43px !important;
  color: #23508d;
}
h3 {
  font-family: "Poppins", sans-serif;
  font-size: 24px !important;
  line-height: 29px !important;
}
h4 {
  font-family: "Chivo";
  font-size: 20px !important;
  line-height: 24px !important;
  color: #23508d;
  font-weight: 400 !important;
}
h5 {
  font-family: "Chivo";
  font-size: 18px;
  line-height: 21px !important;
}
h6 {
  font-family: "Poppins", sans-serif;
  font-size: 14px !important;
  line-height: 21px !important;
  margin-bottom: 0px !important;
}
p {
  font-family: "Poppins", sans-serif;
  font-size: 16px !important;
  line-height: 24px !important;
  margin-bottom: 0px !important;
}
a {
  font-family: "Chivo";
  font-weight: 400;
  font-size: 16px !important;
  line-height: 19px;
  text-decoration: none !important;
}
.font16 {
  font-family: "Chivo";
  font-weight: 400;
  font-size: 16px !important;
  line-height: 19px !important;
  margin-bottom: 2px;
}
.font14 {
  font-family: "Poppins", sans-serif;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #ffffff;
}
li {
  list-style: none;
}
ul {
  padding: 0px !important;
  margin: 0px !important;
}
.up {
  -webkit-animation: uparrow 0.6s infinite alternate ease-in-out;
  margin-right: 8px;
  position: relative;
  z-index: 1;
}

@keyframes uparrow {
  0% {
    -webkit-transform: translateX(0);
    opacity: 0.9;
  }
  100% {
    -webkit-transform: translateX(-0.4em);
    opacity: 0.9;
  }
}

@media only screen and (max-width: 1200px) {
  h1 {
    font-size: 40px !important;
  }
}

@media only screen and (max-width: 767px) {
  h2 {
    font-size: 25px !important;
    line-height: 30px !important;
  }
}
@media only screen and (max-width: 580px) {
  h2 {
    font-size: 30px !important;
    line-height: 35px !important;
  }
  h4 {
    font-size: 15px !important;
  }
  h3 {
    font-size: 20px !important;
    line-height: 20px !important;
  }
}

@media only screen and (max-width: 480px) {
  h1 {
    font-size: 35px !important;
    line-height: 35px !important;
  }
  h3 {
    font-size: 18px !important;
    line-height: 20px !important;
  }
}

.erro-banner {
  background: #f8f8fc url(../../img/404-bg.png) center no-repeat;
  background-size: 100% 100%;
}
.error-text {
  padding-top: 159px;
  padding-bottom: 309px;
}
.error-title {
  font-family: "Chivo";
  font-weight: 400;
  font-size: 150px;
  line-height: 178px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 1px;
  color: #16a7e5;
}
.error-text h2 {
  font-size: 36px;
  color: white;
  text-align: center;
  font-family: "Chivo";
  margin-bottom: 41px;
}
.go-btn.text-white {
  background: #16a7e5;
  box-shadow: 0px 7px 15px rgba(35, 80, 141, 0.2);
  border-radius: 5px;
  padding: 15px 25px;
}
.contact-btn {
  background: #ffffff;
  box-shadow: 0px 7px 15px rgba(35, 80, 141, 0.2);
  border-radius: 5px;
  padding: 15px 25px;
  margin-left: 15px;
}
.appointment-today {
  background-color: #f8f8fc;
  padding-top: 89px;
  padding-bottom: 108px;
}
.bg-today {
  background: #ffffff;
  box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.2);
  border-radius: 8px;
  position: relative;
  z-index: 1;
}
.inner-bg-today {
  padding: 68px 65px;
}
.bg-today::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: url("../../img/bg (12).png") center no-repeat;
  pointer-events: none;
}
.left-title p {
  max-width: 588px;
  margin-bottom: 20px !important;
}
a.an-appt {
  background: #23508d;
  border-radius: 5px;
  color: white;
  padding: 15px 41px;
}
a.go-btn:hover {
  background: #fff;
  box-shadow: 0px 7px 15px rgb(35 80 141 / 20%);
  border-radius: 5px;
  padding: 15px 25px;
  color: #000 !important;
  transition: all 0.5s ease;
}
a.contact-btn:hover {
  background: #16a7e5;
  box-shadow: 0px 7px 15px rgb(35 80 141 / 20%);
  border-radius: 5px;
  padding: 15px 25px;
  color: #fff !important;
  transition: all 0.5s ease;
}
a.an-appt:hover {
  background: #000;
  color: white;
  transition: all 0.5s ease;
}
.error-btn{
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .error-text {
    padding-top: 80px;
    padding-bottom: 100px;
  }
  .inner-bg-today {
    padding: 30px 30px;
}
}

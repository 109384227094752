.admin img {
  width: 80px;
  height: 80px;
  border-radius: 50px;
}
.blog-bg {
  background: #f8f8fc;
  box-shadow: 0px 8px 24px rgb(149 157 165 / 20%);
  border-radius: 8px;
  padding: 25px;
}
.admin {
  margin-bottom: 24px;
}
.blog-colon {
  margin-bottom: 28px;
}
.blog-bg:hover {
  background: #ffffff;
  box-shadow: 0px 8px 24px rgb(149 157 165 / 20%);
  border-radius: 8px;
}
.blog-bg:hover .blog-colon1 {
  display: block !important;
  margin-bottom: 28px;
}
.blog-bg:hover .blog-colon {
  display: none !important;
}
.blog-bg:hover .blog-read h4 {
  color: #23508d !important;
}
.review .explore-btn {
  padding-top: 51px;
}
.review {
  padding-top: 90px;
  padding-bottom: 66px;
}
.blog-para p {
  max-width: 329px;
  margin: 0 auto;
  margin-bottom: 29px !important;
}
.patient-text h4 {
  display: inline-block;
}

@media only screen and (max-width: 991px) {
  .review {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-para p {
    max-width: 100%;
  }
  .review {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.service {
  background-color: #f8f8fc;
  padding-top: 92px;
  padding-bottom: 98px;
}
.offer h2 {
  margin-bottom: 18px !important;
}
.service-para p {
  max-width: 616px;
  margin: 0 auto;
  margin-bottom: 32px !important;
}
.inner-content {
  padding: 40px 19px 40px 40px;
}
.social-box {
  background: #f8f8fc;
  box-shadow: 0px 8px 15px rgba(149, 157, 165, 0.15);
  border-radius: 5px;
}
.custmor-serv-img {
  margin-bottom: 37px;
}
.inner-content h4 {
  margin-bottom: 20px;
}
.inner-content p {
  margin-bottom: 40px !important;
  max-width: 322px;
}
.social-box:hover {
  background: #ffffff;
  box-shadow: 0px 8px 15px rgba(149, 157, 165, 0.15);
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}
.explore-btn {
  padding-top: 71px;
  text-align: center;
  font-size: 20px !important;
  font-weight: 400;
  color: #23508d;
  font-family: "Chivo";
}

@media only screen and (max-width: 991px) {
  .explore-btn {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .inner-content {
    padding: 40px 19px 40px 19px;
  }
  .service {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .inner-content p {
    max-width: 100%;
  }
}
@media only screen and (max-width: 580px) {
  .service {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .explore-btn {
    font-size: 18px !important;
  }
}

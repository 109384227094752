.healthcare-text h4 {
  margin-bottom: 20px;
}
.blog-top-para h4 {
  margin-bottom: 27px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #202020;
}
.blog-middle-para p {
  margin-bottom: 20px !important;
}
.post-image {
  margin-bottom: 39px;
  background: #ffffff;
  box-shadow: 0px 8px 24px rgb(149 157 165 / 20%);
  border-radius: 5px;
}
.blogDetails .posting-blog{
  padding-bottom: 0;
}
.posting-blog {
  padding-top: 68px;
  padding-bottom: 111px;
  background-color: #f8f8fc;
}
.posting-blog .invitation-box {
  margin-bottom: 19px;
  background: #23508d;
  border-radius: 5px;
  padding: 27px 0px 0px 35px;
}
.blog-btm-para p {
  margin-bottom: 20px !important;
}
.blog-last-para p {
  margin-bottom: 11px !important;
}

.list-menu {
  background: #ffffff;
  padding: 13px 23px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.list-menu a {
  color: #000;
}
.blog-right h4 {
  margin-bottom: 20px;
}
.blog-post-wrap {
  background: #23508d;
  position: relative;
  z-index: 1;
  padding: 24px 21px;
}
.blog-post-wrap::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url("../../img/blog-post-bg.png") center no-repeat;
  background-size: cover;
}
ul.item-menu li.list-menu:hover {
  background: #23508d;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 5px;
}
ul.item-menu li.list-menu:hover a {
  color: white;
}
.dont-text {
  font-family: "Chivo";
}
.emergency-btn {
  background: #16a7e5;
  box-shadow: 0px 7px 15px rgb(35 80 141 / 20%);
  border-radius: 5px;
  padding: 16px 20px;
}
.blog-post-wrap h4 {
  margin-bottom: 12px;
}
.post-para {
  margin-bottom: 34px !important;
}
.list-menu:nth-child(8) {
  margin-bottom: 47px;
}
.read-more a {
  color: #23508d !important;
}
.blog-post img {
  width: 100%;
  height: 100%;
}
.service-post-img img {
  width: 100%;
  max-width: 894px;
  margin-bottom: 39px;
}
.contact-map img {
  width: 100%;
}

@media only screen and (max-width: 991px) {
  .emergency-btn {
    display: inline-block;
  }
}

.blog-post {
  position: relative;
}
.date-btn {
  position: absolute;
  top: 0;
  background: #23508d;
  border-radius: 5px;
  padding: 9px 8px;
  margin-left: 10px;
  margin-top: 22px;
  font-family: "Chivo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}
.medicald-blog {
  padding-top: 420px;
  padding-bottom: 94px;
  /* background-color: #f8f8fc; */
  margin-top: -420px;
}
.blog-medical {
  background: #f8f8fc;
  padding-bottom: 126px;
}
.medicald-blog .top-para {
  max-width: 616px;
  margin-bottom: 49px !important;
}
.medical-text {
  box-shadow: 0px 8px 24px rgb(149 157 165 / 20%);
  border-radius: 5px;
  padding: 26px 30px;
  height: 196px;
}
.medical-text p {
  margin-bottom: 8px !important;
}
.blog-wrap {
  margin-bottom: 29px;
  background: #f8f8fc;
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 5px;
  height: 150px;
}
.blog-post-content {
  padding-left: 14px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.blog-text {
  font-family: "Chivo";
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #202020;
  margin-bottom: 12px;
}
.blog-post-content p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #202020;
}
.blog-post-wrap {
  /* background: #23508d url(../../img/) center no-repeat; */
  background-size: cover;
}

.blogPage .blogHeading {
  display: none;
}
.blogPage .medicald-blog {
  margin-top: -350px;
}
.post-border-top {
  border-top: 1px solid #caced2;
  margin-bottom: 16px;
}

@media only screen and (max-width: 1200px) {
  .blog-post-content {
    padding-left: 14px;
  }
}
@media only screen and (max-width: 767px) {
  .blog-wrap {
    height: auto;
  }
  .blog-right {
    padding-left: 0;
    margin-top: 45px;
  }
  .medical-text {
    height: auto;
  }
  .medicald-blog .top-para {
    margin-bottom: 20px !important;
  }
}

@media only screen and (max-width: 580px) {
  .blog-wrap {
    flex-direction: column;
  }
}

.blog-details-right {
  background: #ffffff;
  border: 1px solid rgba(149, 157, 165, 0.2);
  border-radius: 5px;
  padding: 28px 30px;
}
.blog-details-right .list-menu {
  background: #ffffff;
  border: 1px solid rgba(149, 157, 165, 0.2);
  border-radius: 5px;
}
.blog-details-right .list-menu:hover a {
  color: #23508d;
}
.blog-details-left {
  background: #ffffff;
  border: 1px solid rgba(149, 157, 165, 0.2);
  border-radius: 5px;
  padding: 28px 26px 28px 30px;
}
.medical-health a {
  padding: 12px 18px;
  background: #23508d;
  border-radius: 5px;
  display: inline-block;
  color: white;
  margin-bottom: 21px;
}
.health-title {
  font-family: "Chivo";
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  color: #202020;
  margin-bottom: 15px;
}
.posted-date {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #a9a9a9;
  margin-bottom: 15px;
}
.details-para {
  margin-bottom: 15px !important;
}
ul.health-item {
  padding-left: 2rem !important;
  margin-bottom: 15px !important;
}
li.health-list {
  list-style: unset;
}
.details-infor p {
  margin-bottom: 15px !important;
}
.posting-blog .bg-contact {
  margin-top: 100px;
}
.post-border-top {
  border-top: 1px solid #caced2;
  margin-bottom: 16px;
}
.post-border-btm {
  border-bottom: 1px solid #caced2;
  margin-bottom: 23px;
}
.related-info {
  margin-bottom: 16px;
}
.nav-tabs {
  border-bottom: none !important;
}
ul.nav.nav-tabs li a {
  background: #f8f8fc;
  color: #a9a9a9;
  margin-right: 10px;
}
.related-tag {
  padding-right: 19px;
}
.share-on {
  padding-right: 15px;
}
.share-icon a {
    border: 1px solid black;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
}
.share-icon a svg{
    color: #202020;
}
.share-icon:hover a {
  background: #23508d;
  border: 1px solid #23508d;
}
.blog-details-form .form-control {
  border: none !important;
  box-shadow: inherit;
  background: #f8f8fc !important;
  border-radius: 5px !important;
  margin-bottom: 30px;
  padding: 21px 23px !important;
}
.blog-details-btn {
  background: #23508d !important;
  box-shadow: 0px 0px 0px 1px rgb(0 0 0 / 6%) !important;
  border-radius: 5px !important;
  padding: 16px 26px !important;
  color: #fff !important;
  margin-bottom: 27px;
}

.comming-soon {
  background-color: #f8f8fc;
  position: relative;
  z-index: 1;
}
.profess-img img {
  width: 100%;
  height: 100%;
}
.profess-img {
  height: 100%;
  max-width: 291px;
  float: right;
  z-index: 1;
  position: relative;
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
.text-left-comming {
  padding-bottom: 129px;
  padding-top: 80px;
}

.dropdown-menu {
  padding: 21px 15px 0px 15px !important;
}
.comming-soon::before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: url("../../img/comming-bg.png") center no-repeat;
  pointer-events: none;
  position: absolute;
}
.comming-soon-text {
  font-family: "Chivo";
  font-weight: 400;
  font-size: 60px;
  line-height: 71px;
  color: #23508d;
  margin-bottom: 12px;
}
.our-best h3 {
  font-family: "Chivo";
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #202020;
  margin-bottom: 36px;
}
.subscribe-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #202020;
  margin-bottom: 12px !important;
}
.form-control {
  border: none !important;
  background: #ffffff !important;
  box-shadow: 0px 8px 24px rgb(149 157 165 / 20%);
  border-radius: 5px !important;
}
.btn.submit-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #23508d;
  border-radius: 5px;
  margin: 5px;
  color: white;
  padding: 10px 26px;
}
.email-form {
  max-width: 500px;
}

@media only screen and (max-width: 991px) {
  .email {
    margin-bottom: 10px;
  }
  .appointment-btn {
    float: inherit;
    margin-bottom: 10px;
  }
  .comming-soon-text {
    font-size: 46px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .email-form {
    margin: 0 auto;
  }
  .text-left-comming {
    text-align: center;
  }
  .profess-img {
    float: inherit;
    margin: 0 auto;
  }
  .text-left-comming {
    padding-bottom: 50px;
    padding-top: 50px;
  }
}

@media only screen and (max-width: 580px) {
  .comming-soon-text {
    font-size: 30px;
    line-height: 50px;
  }
}

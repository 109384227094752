select:required:invalid {
  color: #a9a9a9;
}
option[value=""][disabled] {
  display: none;
}
option {
  color: #000;
}
.opening-details {
  padding-right: 0px;
  padding-left: 0px;
}
.bg-contact {
  background: #ffffff;
  box-shadow: 0px -8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 5px;
  padding: 54px 27px 41px 20px;
  position: relative;
}
section.contact-us {
  margin-bottom: 94px;
}
.content-text p {
  max-width: 432px;
  margin-bottom: 20px !important;
}
.h6 {
  color: #23508d;
  margin-bottom: 26px !important;
}
.contact-details {
  margin-bottom: 13px;
}
.map {
  padding-top: 43px;
}
.contact-para p {
  margin-bottom: 8px !important;
}
.email-icon::before {
  content: "";
  position: absolute;
  background: url("../../img/mail-01.png") center no-repeat;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 18px;
  height: 18px;
}
.map-icon::before {
  content: "";
  position: absolute;
  background: url("../../img/marker-pin-01 (1).png") center no-repeat;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 18px;
  height: 18px;
}
.tel-icon::before {
  content: "";
  position: absolute;
  background: url("../../img/telephone 1.png") center no-repeat;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 18px;
  height: 18px;
}
.contact-details ul {
  padding: 0;
}
.contact-details a {
  padding-left: 29px;
}
.contact-details li {
  margin-bottom: 15px;
  font-family: "Chivo";
  font-weight: 400;
  font-size: 16px;
  color: #202020;
}
.btn.submit {
  background: #23508d;
  box-shadow: 0px 7px 15px rgb(35 80 141 / 20%);
  border-radius: 5px;
  color: white;
  padding: 16px 24px;
}
.form-control,
.form-select {
  background: #f8f8fc !important;
  border: 1px solid rgba(35, 80, 141, 0.2) !important;
  border-radius: 5px !important;
  padding: 15px 16px !important;
}
.map {
  max-width: 536px;
}
.form-bg {
  padding-left: 51px;
  z-index: 1;
}
.cont-form .form-control {
  padding: 15px 16px !important;
  margin-bottom: 11px !important;
}
.form-bg::before {
  content: "";
  z-index: -1;
  background: url("../../img/contact-frm-bg.png") center no-repeat;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  background-size: 100% 100%;
}
p.right-para {
  max-width: 350px;
}
textarea#exampleFormControlTextarea1 {
  padding: 15px 16px !important;
}
select.country-select {
  width: 100%;
  border: none !important;
  box-shadow: inherit;
  background: #f8f8fc !important;
  border-radius: 5px !important;
  margin-bottom: 30px;
  padding: 21px 23px !important;
}

/* contact page */
.contact-no h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #202020 !important;
}
.get-in-touch {
  background-color: #f8f8fc;
  padding-bottom: 100px;
}
.social-icon-wrap {
  margin-bottom: 31px;
}
.contact-no {
  padding-left: 18px;
}
.help-title h3 {
  margin-bottom: 30px;
}
.contact-form {
  background: #ffffff;
  box-shadow: 0px 8px 24px rgb(149 157 165 / 20%);
  border-radius: 5px;
  padding: 25px 30px;
}
.contact-form .form-control {
  background: #f8f8fc !important;
  border: 1px solid rgba(35, 80, 141, 0.2) !important;
  border-radius: 5px !important;
}
.contact-form .form-control ::-webkit-input-placeholder {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #a9a9a9;
}
.contact-form .form-control :-ms-input-placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #a9a9a9;
}
.contact-form .form-control::placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #a9a9a9;
}
form.contact-form {
  margin-top: -80px;
  position: relative;
}
.we-help {
  padding-top: 53px;
  padding-right: 148px;
}
button.btn.form-submit {
  background: #23508d;
  box-shadow: 0px 7px 15px rgb(35 80 141 / 20%);
  border-radius: 5px;
  color: white;
  padding: 10px 24px;
}
.icon-social-media a {
  border: 1px solid black;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: inline-flex;
  text-align: center;
  vertical-align: middle;
  padding: 6px;
  margin-left: 5px;
}
.icon-social-media {
  padding-left: 4px;
}
.contact-map {
  padding-top: 100px;
}
.contact-img img {
  width: 100%;
}
.contact-img {
  padding-right: 54px;
  max-width: 461px;
}
.icon-social-media svg{
  color: #202020;
}
@media only screen and (max-width: 991px) {
  form.contact-form {
    margin-top: 0;
  }
  .contact-map {
    padding-top: 50px;
  }
  .get-in-touch {
    padding-bottom: 50px;
  }
  .map {
    max-width: 100%;
}
}
@media only screen and (max-width: 767px) {
  .we-help {
    padding-right: 0;
  }
  .form-bg {
    padding-left: 0px;
  }
  .bg-contact {
    padding: 54px 20px 41px 20px;
  }
}

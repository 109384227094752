img.special-doctr {
  max-width: 100%;
  width: 100%;
}
a.moreless-button {
  background: #ffffff;
  width: 38px;
  height: 38px;
  border-radius: 40px;
}
.member-btn {
  float: right;
  background: #23508d;
  box-shadow: 0px 7px 15px rgba(35, 80, 141, 0.2);
  border-radius: 5px;
  padding: 16px 15px;
}
.specialist {
  background-color: #f8f8fc;
  padding: 92px 0px;
  z-index: 1;
}
.specialist::before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 71px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: url("../../img/specialist-bg.png") center no-repeat;
  height: 465px;
  pointer-events: none;
}
.para {
  max-width: 616px;
  margin-bottom: 27px !important;
}
.expert {
  margin-bottom: 18px;
}
.doctor-info-box {
  background: #ffffff;
  box-shadow: 0px 8px 24px rgb(149 157 165 / 20%);
  border-radius: 5px;
  padding: 20px 22px;
}
.doct-image {
  margin-bottom: 17px;
  position: relative;
}
.doctor-social-media {
  position: absolute;
  bottom: 19px;
  left: 40px;
}
.doctor-social-media a {
  border: 1px solid white;
  border-radius: 40px;
  padding: 9px;
  display: inline-block;
  margin: 0px 5px;
}
.color-overlay {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 41.09%,
    #23508d 89.78%
  );
  filter: drop-shadow(0px -16px 16px rgba(35, 80, 141, 0.07));
  border-radius: 5px;
  position: absolute;
  height: 50%;
  width: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.doctor-info-box:hover .color-overlay {
  display: block !important;
}
.doctor-info-box:hover .doctor-social-media {
  display: block !important;
}
.doctor-info-box:hover .doct-image ::before {
  display: block !important;
}
.doctor-info-box:hover .doctor-info h3 {
  color: #23508d;
}

@media only screen and (max-width: 991px) {
  .specialist {
    padding: 50px 0px;
  }
  .member-btn {
    float: left;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .specialist {
    padding: 50px 0px;
  }
  .member-btn {
    float: inherit;
    display: inline-block;
    margin-bottom: 20px;
  }
}

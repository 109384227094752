.choose-us {
  background: #23508d;
}
.choose-wrap {
  padding: 95px 0px 0px 0px;
}
.choose-wrap .medium-heading h2 {
  color: #16a7e5 !important;
}
.interv::before {
  content: "";
  position: absolute;
  background: url("../../img/choose-arrow.png") center no-repeat;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 23px;
  height: 23px;
}
.interv {
  padding-left: 30px;
  margin-bottom: 26px;
}
.invitation-box {
  margin-bottom: 50px;
}
.inner-number {
  margin-bottom: 23px;
}
.doctor-img {
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%;
  bottom: 0;
}
.doctor-img img {
  width: 100%;
  height: 100%;
}
.choose-wrap .sub-heading h4::before {
  background: #fff;
}
.number-box {
  margin-bottom: 61px;
}
.row.exr-ss:first-child {
  padding-top: 0;
  border-top: 0;
}

.row.exr-ss {
  border-top: 1px solid #3066ae;
  padding-top: 17px;
}
.row.exr-ss
  .col-xl-6.col-lg-12.col-md-12:first-child
  .inner-number.d-flex.align-items-center {
  border-left: 0;
  padding-left: 0;
}
.inner-number.d-flex.align-items-center {
  border-left: 1px solid #3066ae;
  padding-left: 77px;
  margin-bottom: 0;
  margin-top: -18px;
  padding-bottom: 27px;
  padding-top: 29px;
}
a.learn-more {
  color: #23508d;
  padding-left: 7px;
}
.doctor-social-media a.active {
  background: #16a7e5;
  border: 1px solid #16a7e5;
}
@media only screen and (max-width: 1200px) {
  .doctor-img {
    position: inherit;
    width: 100%;
    max-width: 654px;
  }
  .choose-wrap {
    padding: 50px 0px 0px 0px;
  }
  .inner-number.d-flex.align-items-center {
    border-left: 0 solid #3066ae;
    padding-left: 0px;
    margin-bottom: 0;
    margin-top: 0px;
    padding-bottom: 0px;
    padding-top: 26px;
  }
  .row.exr-ss {
    border-top: 0 solid #3066ae;
  }
  .choose-us {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 991px) {
  .invitation-box {
    margin-bottom: 5px;
  }
  .number-box {
    margin-bottom: 5px;
  }
  .inner-number.d-flex.align-items-center {
    border-left: 0 solid #3066ae;
    padding-left: 0px;
    margin-bottom: 0;
    margin-top: 0px;
    padding-bottom: 0px;
    padding-top: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .doctor-img {
    padding-top: 50px;
  }
  .choose-us {
    padding-bottom: 50px;
  }
}

.caseStudyBG section.contact-us {
  padding-bottom: 94px;
  background-color: #f8f8fc;
}
.pages-info-box:hover h4 {
  color: #23508d !important;
}
.pages-info-box {
  background-color: #f8f8fc;
  box-shadow: 0px 8px 24px rgb(149 157 165 / 20%);
  border-radius: 5px;
  padding: 14px 20px;
}
.pages-info-box img{
width: 100%;
}
.pages-info-box:hover {
  background: #ffffff;
  box-shadow: 0px 8px 24px rgb(149 157 165 / 20%);
  border-radius: 5px;
  padding: 14px 20px;
}
.pages-specialist {
  background-color: #f8f8fc;
  padding: 92px 0px 81px 0px;
}
section.page-contact {
  background-color: #f8f8fc;
  padding: 100px 0px;
}
.pages-specialist .explore-btn {
  padding-top: 50px;
  /* margin-bottom: 100px; */
}
.blog-left h2 {
  margin-bottom: 19px;
}
.challenge-para {
  margin-bottom: 30px !important;
}
.blog-top-para h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #202020;
}
.case-btn {
  font-size: 20px !important;
  font-weight: 400;
  color: #23508d;
  font-family: "Chivo";
  padding-top: 25px;
  padding-bottom: 39px;
}
.inpatient-img img{
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .pages-specialist {
    padding: 50px 0px 0px 0px;
  }
  .page-contact {
    padding: 50px 0px;
  }
}

.about-img img {
  width: 100%;
}
.about {
  background: url("../../img/about-new-bg.png") no-repeat;
  padding-bottom: 133px;
  padding-top: 132px;
  background-size: contain;
  margin-bottom: 81px;
}
.about-content .more-btn::before {
  background-color: #23508d;
}
.medium-heading h2 {
  color: #23508d !important;
  margin-bottom: 27px;
}
.about-img {
  display: flex;
  justify-content: flex-end;
  z-index: 1;
  position: relative;
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateX(-10px);
  }
}
.about-para p {
  margin-bottom: 27px !important;
}
.sub-heading h4 {
  padding-left: 38px;
  font-family: "Poppins", sans-serif;
}
.sub-heading h4::before {
  content: "";
  position: absolute;
  width: 25px;
  height: 3px;
  background: #000;
  border-radius: 100px;
  top: 10px;
  left: 0;
}
.best-service {
  padding-right: 50px;
}
.about-img img {
  max-width: 457px;
}
.about-para p {
  max-width: 616px;
}
.best-service p {
  width: 100%;
  max-width: 290px;
}
.expert-team p {
  max-width: 290px;
}
.vari-pass {
  margin-bottom: 47px;
}
.more-btn {
  padding-right: 40px;
}
.contact-info p {
  color: #23508d;
  font-family: "Chivo";
}
.contact-info {
  padding-left: 14px;
}

@media only screen and (max-width: 1200px) {
  .about-img {
    justify-content: flex-start;
  }
  .about-banner .row {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .about {
    padding-bottom: 70px;
  }
}

@media only screen and (max-width: 991px) {
  .about-contact-form {
    padding-bottom: 50px;
  }
  .about-para p{
    max-width: 100%;
  }
  .vari-pass.d-flex{
    justify-content: space-between;
  }
  .about-img img{
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .mobile-img img {
    width: 30px;
  }
  .btn-more {
    flex-wrap: wrap;
  }
  .about {
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .expert-team p {
    max-width: 100%;
  }
  .best-service p {
    max-width: 100%;
  }
}
@media only screen and (max-width: 580px) {
  .vari-pass {
    flex-direction: column;
  }
  .best-service p {
    margin-bottom: 20px !important;
  }
}

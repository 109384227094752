.accourdian {
  background-color: #f8f8fc;
  padding-bottom: 100px;
  padding-top: 35px;
  z-index: 1;
}
.accourdian:before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: url("../../img/accourdian-bg.png") center no-repeat;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.accordion-item {
  margin-bottom: 22px;
  border: none !important;
}
button.accordion-button.collapsed {
  background: #f8f8fc;
  box-shadow: 0px 8px 24px rgb(149 157 165 / 20%);
  border-radius: 5px;
}
.accordion-button .collapsed {
  font-family: "Chivo";
  font-weight: 400;
  font-size: 18px !important;
  line-height: 21px;
  color: #202020 !important;
}
.accordion-body {
  max-width: 966px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 24px;
  color: #202020 !important;
}
.accordion-button {
  background: #f8f8fc;
  box-shadow: 0px 8px 24px rgb(149 157 165 / 20%);
  border-radius: 5px;
}
.accordion-button.collapsed:focus {
  background: #f8f8fc !important;
  box-shadow: 0px 8px 24px rgb(149 157 165 / 20%) !important;
  border-radius: 5px !important;
}

.accordion-button::after {
  flex-shrink: 0;
  width: 25px !important;
  height: 25px !important;
  margin-left: auto !important;
  content: "";
  background: url("../../img/plus1.png") center no-repeat !important;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
.accordion-button:focus {
  border: transparent;
}
.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  color: #0c63e4;
}
.accordion-button:not(.collapsed)::after {
  background: url("../../img/sub1.png") center no-repeat !important;
  width: 25px !important;
  height: 25px !important;
  transform: rotate(-180deg);
}
.accordion-button:focus{
  box-shadow: none !important;
}
.accourdian .explore-btn {
  padding-top: 46px;
  text-align: center;
}
.accordion {
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 991px) {
  .accourdian {
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .accourdian {
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .faq-contact {
    margin-top: 50px;
  }
  .accordion-button {
    font-size: 16px !important;
    line-height: 27px !important;
  }
  .accordion-body {
    font-size: 14px !important;
  }
}
